<template>
  <div class="row m-0 px-lg-4" :class="[isMobile || isTablet ? 'pt-4' : '']">
    <div class="col-12 px-lg-5">
      <div class="row m-0 p-0" v-for="sml, i in myQRLinks" :key="i">
        <div :class="[isMobile || isTablet ? 'col' : 'col-5']" class="text-left">
          <menu-item :menuItem="sml" :isMobile="true" @selectItem="selectItem" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  name: 'LodgeInfoPage',
  components: {
    'menu-item': () => import('@/components/MenuItem.vue')
  },
  computed: {
    ...mapGetters([
      'isMobile',
      'isTablet'
    ])
  },
  async created () {
    const res = await this.$api.guest.nextJourney()
    const journey = res.data
    const lodgeIds = []
    if (journey && journey.properties) {
      for (const property of journey.properties) {
        if (property.lodge && !lodgeIds.includes(property.lodge.id) && property.lodge.qr) {
          lodgeIds.push(property.lodgeId)
          this.myQRLinks.push({
            ...property.lodge.qr,
            type: 'qrcode',
            title: property.lodge.name
          })
        }
      }
    }
  },
  data () {
    return {
      myQRLinks: []
    }
  },
  methods: {
    selectItem (sml) {
      window.open(`https://${sml.link}`, '_blank')
    }
  }
}
</script>
<style scoped>
</style>
